import { Layout } from '@components/Layout'
import { Footer } from '@components/Layout/components/Footer'
import { CompleteHeader } from '@components/Layout/components/header/CompleteHeader'
import { TopBanner } from '@components/Layout/components/TopBanner'
import { Home } from '@features/home'
import { HomeSeo } from '@features/home/seo'
import { getStaticHomeProps } from '@features/home/static'
import { HomeStaticProps } from '@features/home/static/types'
import { GetStaticProps } from 'next'

export default function Index({
    homeData,
    homeLayout,
    categoriesData,
    staticRecommendations,
    topBannerStaticContent,
    seoData
}: HomeStaticProps): JSX.Element {
    return (
        <>
            <HomeSeo homeData={homeData} seoData={seoData} />
            <Layout
                topBanner={
                    <TopBanner
                        topBannerStaticContent={topBannerStaticContent}
                    />
                }
                navbarComponent={
                    <CompleteHeader categoriesData={categoriesData} />
                }
                footer={<Footer categoriesData={categoriesData} />}
                contentTopPadding="lg"
            >
                <Home
                    seoData={seoData}
                    homeData={homeData}
                    homeLayout={homeLayout}
                    categoriesData={categoriesData}
                    staticRecommendations={staticRecommendations}
                    topBannerStaticContent={topBannerStaticContent}
                />
            </Layout>
        </>
    )
}

export const getStaticProps: GetStaticProps = getStaticHomeProps({
    isDesktop: true
})
